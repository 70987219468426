import {useUIManager} from "../hooks/useUIManager";
import {useEffect} from "react";
import {Heading, VStack, Text, Image, Box, HStack, Icon, Button, Flex} from "@chakra-ui/react";
import {useAPI} from "../hooks/useAPI";
import {HiOutlineSparkles} from "react-icons/hi";
import {Link as RouterLink} from "react-router-dom";

export const IndexPage = () => {
  const api = useAPI();
  const uiManager = useUIManager();
  useEffect(() => {
    uiManager.toggleNeedReloadItems(true);
    uiManager.toggleNeedReloadProfile(true);
  }, [uiManager])

  return <>
    <VStack gap={3}>
      <VStack mt={5}>
        {api.loggedIn && (<Box bg="white" boxShadow="sm" borderRadius="md" w="100%" p={{base: "1.5rem", md: 5}} mb="2rem">
          <Flex justifyContent="space-between" alignItems="center">
          <HStack gap={{base: 1, md: 5}}>
            <Icon as={HiOutlineSparkles} boxSize="32px" color="brand.500"/>

            <VStack alignItems="start" gap={0}>
              <Text fontWeight="bold" mb={0}>Hey, {api.loggedInUser?.name}!</Text>
              <Text marginTop="0!important">How're you doing?</Text>
            </VStack>
          </HStack>
          <Button as={RouterLink} to="/profile" colorScheme="brand" size="sm">Go to Profile</Button>
          </Flex>
        </Box>)}

        {!api.loggedIn && (<Heading size="sm" as="h5" color="brand.500">Hey there</Heading>)}
        <Heading size="xl" textAlign="center">Your wishlist for family and friends</Heading>
        <Text textColor="gray.500" textAlign="center" w="100%" maxW={{base: "100%", lg: "75%"}}>
          This is a nice place where you can manage your wishlists, so your family and friends always know what do you want to receive on special occasions.
        </Text>

        <Image src={require("../assets/banner.webp")} alt="Demo picture"/>

        {!api.isLoading && !api.loggedIn && <Button as={RouterLink} to="/signin" colorScheme="brand" size="md" mt={2}>Sign Up</Button>}
      </VStack>
    </VStack>
  </>
}
