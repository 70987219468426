import {ResponsiveValue, ThemeTypings} from "@chakra-ui/styled-system";

export interface Event<T = EventTarget> {
  target: T;
}

export declare type Token<CSSType, ThemeKey = unknown> = ThemeKey extends keyof ThemeTypings ? ResponsiveValue<CSSType | ThemeTypings[ThemeKey]> : ResponsiveValue<CSSType>;

export interface ItemType {
  uuid: string
  name: string
  description: string | null
  image: string | null
  link: string | null
}

export default class UnauthorizedError extends Error {}
export class NetworkError extends Error {}
export class CaptchaError extends Error {}

export const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

