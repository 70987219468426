import {Box, Icon, IconButton, Image, Input, LinkBox, LinkOverlay, Text} from "@chakra-ui/react";
import {CloseIcon} from "@chakra-ui/icons";
import {BiImageAdd} from "react-icons/bi";
import React, {useEffect, useState} from "react";
import * as CSS from "csstype";
import {Event, Token} from "../../services/types";

interface FileFormProps {
  w?: Token<CSS.Property.Width | number, "sizes">;
  mb?: Token<CSS.Property.MarginInlineEnd | number, "space">;
  me?: Token<CSS.Property.MarginInlineEnd | number, "space">;
  picture: string
  setPicture: (picture: string) => any
  setUploadedFile: (uploadedFile: Blob | MediaSource | null) => any
}

export const FileForm = ({w, me, mb, picture, setPicture, setUploadedFile}: FileFormProps) => {
  const [imagePreview, setImagePreview] = useState<string | null>(picture);
  useEffect(() => {
    setImagePreview(picture);
  }, [picture]);
  const fileRef = React.useRef<HTMLInputElement>(null);
  const chooseFile = () => {
    if (!fileRef.current) {
      console.error("File ref is NULL");
      return;
    }

    fileRef.current.click();
  }
  const loadImagePreview = (event: Event<HTMLInputElement>) => {
    if (!event.target || event.target.files === null) {
      return;
    }

    if (event.target.files.length && event.target.files[0]) {
      setImagePreview(URL.createObjectURL(event.target.files[0]));
      setPicture(URL.createObjectURL(event.target.files[0]));
      setUploadedFile(event.target.files[0]);
    }
  }
  const clearImagePreview = () => {
    setImagePreview(null);
    setPicture('');
    setUploadedFile(null);
    if (fileRef.current) {
      fileRef.current.value = '';
    }
  }

  return (
    <LinkBox w={w} mb={mb} me={me}>
      <Box display="flex" w={{base: "100%", md: "240px"}} h={{base: "220px", md: "180px"}} bg="gray.100" mb={3}
           flexDirection="column" alignItems="center" position="relative"
           justifyContent="center">
        <Input ref={fileRef} type="file" accept="image/jpeg,image/png,image/webp" hidden={true}
               onChange={loadImagePreview}/>
        {imagePreview && (
          <>
            <IconButton
              position="absolute"
              top="-5px"
              right="-5px"
              colorScheme="blackAlpha"
              aria-label="Remove"
              borderRadius="full"
              size="xs"
              icon={<CloseIcon/>}
              onClick={clearImagePreview}
            />
            <Image
              src={imagePreview}
              w="100%"
              maxW={{base: "100%", md: "240px"}}
              maxH="100%"
              boxSize="240px"
              objectFit="contain"
            />
          </>
        )}
        {!imagePreview && (
          <LinkOverlay textAlign="center" onClick={chooseFile} cursor="pointer">
            <Icon as={BiImageAdd} boxSize={8} mb={3}/>
            <Text fontSize="xs" textAlign="center" color="gray.500" mx="auto" maxW="75%">Choose an image or
              drag&nbsp;and&nbsp;drop it here</Text>
          </LinkOverlay>
        )}
      </Box>
    </LinkBox>
  )
}
