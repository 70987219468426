import {extendTheme} from "@chakra-ui/react";

export const theme = extendTheme({
  colors: {
    brand: {
      50: '#9898ec',
      500: '#5a5af3',
      600: '#3030a4'
    },
  },
  styles: {
    global: {
      body: {
        bg: 'gray.50'
      }
    }
  }
});
