class Token {
  public readonly accessTokenExpiryAt: Date;
  public readonly refreshTokenExpiryAt: Date;

  constructor(
    public readonly accessToken: string,
    public readonly refreshToken: string,
    accessTokenExpiryAt: string,
    refreshTokenExpiryAt: string,
  ) {
    this.accessTokenExpiryAt = new Date(accessTokenExpiryAt);
    this.refreshTokenExpiryAt = new Date(refreshTokenExpiryAt);
  }

  public isCanBeRefreshed(): boolean {
    return this.refreshTokenExpiryAt > new Date();
  }

  public static deserialize(serialized: string): Token {
    const json = JSON.parse(serialized);

    return new Token(
      json.access_token,
      json.refresh_token,
      json.access_token_expiry_at,
      json.refresh_token_expiry_at,
    );
  }

  public serialize(): string {
    return JSON.stringify({
      access_token: this.accessToken,
      access_token_expiry_at: this.accessTokenExpiryAt,
      refresh_token: this.refreshToken,
      refresh_token_expiry_at: this.refreshTokenExpiryAt,
    });
  }
}

export default Token;
