/* eslint-disable react-hooks/exhaustive-deps */
import {observer} from "mobx-react-lite";
import {Modal, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useMediaQuery} from "@chakra-ui/react";
import {useUIManager} from "../hooks/useUIManager";
import React, {useEffect, useState} from "react";
import EditForm from "./EditProfileModal/EditForm";
import {CropImage} from "./EditProfileModal/CropImage";
import {useNavigate} from "react-router-dom";
import {useAPI} from "../hooks/useAPI";

const EditProfileModal = () => {
  const api = useAPI();
  const [isLg] = useMediaQuery("(min-width: 992px)");
  const uiManager = useUIManager();
  const navigate = useNavigate();

  const fileRef = React.useRef<HTMLInputElement>(null);
  const [step, setStep] = useState("profile");
  const [uploadedFile, setUploadedFile] = useState<string | null>(null);
  const [uploadedFileRaw, setUploadedFileRaw] = useState<Blob | MediaSource | null>(null);
  const [preview, setPreview] = useState<string | null>(api.loggedInUser?.userpic || null);

  useEffect(() => {
    api.checkAuthorization()
      .then((loggedIn) => {
        if (loggedIn) {
          setPreview(api.loggedInUser?.userpic || null);
        }
      })
  }, []);

  const onClose = () => {
    setPreview(preview);
    setUploadedFile(null);
    setUploadedFileRaw(null);

    if (!uiManager.editModalShown) {
      return;
    }

    navigate(uiManager.previousPage || "/profile");
    uiManager.toggleEditModal(false, null);
  }

  return <Modal
    isCentered
    isOpen={uiManager.editModalShown}
    onClose={onClose}
    size={isLg ? '2xl' : 'full'}
  >
    <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)"/>
    <ModalContent>
      <ModalHeader>
        {step === "profile" ? 'Edit profile' : 'Edit media'}
      </ModalHeader>
      {step === "profile" && (
        <>
          <ModalCloseButton/>
          <EditForm
            user={{
              name: api.loggedInUser?.name || '',
              username: api.loggedInUser?.username || '',
              description: api.loggedInUser?.description || ''
            }}
            preview={preview}
            fileRef={fileRef}
            handleClose={onClose}
            setUploadedFileRaw={setUploadedFileRaw}
            setUploadedFile={setUploadedFile}
            changeStep={setStep}
          />
        </>)}
      {step === "media" && (<>
        <CropImage uploadedFile={uploadedFile} uploadedFileRaw={uploadedFileRaw} changeStep={setStep}
                   changePreview={setPreview}/>
      </>)}
    </ModalContent>
  </Modal>
}

export default observer(EditProfileModal);
