import {Box, Flex, Icon, Link, Text} from "@chakra-ui/react";
import {BsHeartFill} from "react-icons/bs";

export const Footer = () => {
  return <>
    <Box mt={10}>
      <Flex direction="column" alignItems="center">
        <Box borderBottomWidth={1} borderBottomColor="gray.300" width="50%" my={5}/>

        <Flex flexDirection="column" alignItems="center" gap={1}>
          <Flex alignItems="center" justifyContent="center" gap={1} w="100%">
            <Text fontSize={{base: "xs", sm: "sm"}}>Made with</Text>
            <Icon as={BsHeartFill} color="red.500"/>
            <Text fontSize={{base: "xs", sm: "sm"}}>
              by <Link href="https://wishlists.cc/cooler241212" color="blue.600"
                       target="_blank">@cooler241212</Link>
            </Text>
          </Flex>
          <Text fontSize={{base: "xs", sm: "sm"}}>
            <Link href="/privacy-policy.html" target="_blank" color="blue.600">Privacy Policy</Link> &bull; All rights reserved &copy; 2023
          </Text>
        </Flex>
      </Flex>
    </Box>
  </>
}
