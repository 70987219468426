import {IconButton} from "@chakra-ui/react";
import {BsPlusLg} from "react-icons/bs";
import {useUIManager} from "../hooks/useUIManager";

export const FabButton = () => {
  const uiManager = useUIManager();
  const onClick = () => {
    if (uiManager.addModalShown) {
      return;
    }

    uiManager.toggleAddModal(true);
  }

  return <>
      <IconButton
        position="fixed"
        bottom="20px"
        right="0px"
        zIndex="99"
        aria-label="Add item"
        icon={<BsPlusLg/>}
        p={3}
        height="64px"
        width="64px"
        fontSize="24px"
        colorScheme="brand"
        borderRadius="full"
        mx="20px"
        boxShadow="dark-lg"
        onClick={onClick}
      />
  </>
}
