// eslint-disable-next-line @typescript-eslint/no-unused-vars
import axios, {AxiosError, AxiosInstance} from 'axios';
import * as AxiosLogger from 'axios-logger';

import {makeObservable, observable, runInAction} from "mobx";
import Token from "./token";
import UnauthorizedError, {CaptchaError, NetworkError} from "./types";
import {
  ParserResponse,
  TokenResponse,
  UserResponse,
  WishlistItemDeleteResponse,
  WishlistItemResponse
} from "./responses";

export default class API {
  private readonly BASE_URL = "https://api.wishlists.cc/v1/"
  private readonly checkAuthInterval = 7500;
  private readonly tokenStorage = "app.wishlists.access_token"

  public isLoading: boolean = true;

  public loggedIn: boolean = false;
  public loggedInUser: UserResponse | null = null;
  public token: Token | null = null;

  private client: AxiosInstance;

  constructor() {
    makeObservable(this, {
      isLoading: observable,
      loggedIn: observable,
      loggedInUser: observable,
    });

    this.client = axios.create({baseURL: this.BASE_URL});
    this.client.interceptors.response.use(
      AxiosLogger.responseLogger,
      AxiosLogger.errorLogger,
    );
  }

  private static authHeader(token: Token) {
    return {
      headers: {
        Authorization: `Bearer ${token.accessToken}`,
      },
    };
  }

  async logout() {
    runInAction(() => {
      this.loggedIn = false;
      this.loggedInUser = null;
      localStorage.removeItem(this.tokenStorage);
    })
  }

  async uploadFile(file: Blob | MediaSource, cropArea: { width: number, height: number, x: number, y: number } | null, userpic: boolean): Promise<string | null> {
    try {
      return await this.makeAuthenticatedRequest(async (token: Token) => {
        return await this.doUploadFile(token, file, cropArea, userpic);
      })
    } catch (error) {
      return null;
    }
  }

  async doUploadFile(token: Token, file: any, cropArea: { width: number, height: number, x: number, y: number } | null, userpic: boolean): Promise<string | null> {
    const formData = new FormData();
    formData.append("file", file)

    if (cropArea) {
      formData.append("crop[width]", "" + cropArea.width);
      formData.append("crop[height]", "" + cropArea.height);
      formData.append("crop[x_offset]", "" + cropArea.x);
      formData.append("crop[y_offset]", "" + cropArea.y);
    }

    const {data} = await this.client.post('https://files.mznx.dev/upload', formData, {
      headers: {
        Authorization: `Bearer ${token.accessToken}`,
        'X-Service-Id': userpic ? 'test-service-nPeZzxSU3eTfctpJ' : 'bc7a5af5-974b-4923-8ad5-572d0c3bd1c9'
      },
    });

    return data.url || null;
  }

  async auth(email: string, captchaAnswer: string) {
    try {
      const {status, data} = await this.client.post('signin', {
        email,
        captcha_answer: captchaAnswer,
      });
      if (status !== 200) {
        return null;
      }

      return data;
    } catch (error: any | AxiosError) {
      if (axios.isAxiosError(error) && error.response) {
        const response = error.response;
        if (response.data.detail.length) {
          for (let err in response.data.detail) {
            // @ts-ignore
            if (JSON.stringify(err["loc"]) === JSON.stringify(["body", "captcha_answer"])) {
              throw new CaptchaError('Invalid captcha');
            }
          }
        }
      }
      return null;
    }
  }

  async getToken(email: string, code: string): Promise<boolean> {
    const token = await this.doGetToken(email, code);
    if (token === null) {
      return false;
    }

    const tokenEntity = new Token(
      token.access_token,
      token.refresh_token,
      token.expiry_at,
      token.refresh_token_expiry_at,
    );
    localStorage.setItem(this.tokenStorage, tokenEntity.serialize());
    runInAction(() => {
      this.token = tokenEntity;
      this.loggedIn = true;
    });
    return true;
  }

  async getMe(token: Token): Promise<UserResponse | null> {
    try {
      const {status, data} = await this.client.get(
        'me',
        API.authHeader(token),
      );
      if (status === 401) {
        return null;
      }

      return data;
    } catch (error) {
      return null;
    }
  }

  async getUser(username: string): Promise<UserResponse | null> {
    try {
      const {status, data} = await this.client.get("user/" + username);
      if (status === 404) {
        return null;
      }

      return data;
    } catch (error) {
      return null;
    }
  }

  async getItem(uuid: string): Promise<WishlistItemResponse | null> {
    try {
      const {status, data} = await this.client.get("/wishlist/items/" + uuid);
      if (status === 404) {
        return null;
      }

      return data;
    } catch (error) {
      return null;
    }
  }

  async patchProfile(name: string, username: string, description: string, userpic: string | null): Promise<UserResponse | null> {
    try {
      return await this.makeAuthenticatedRequest(async (token: Token) => {
        return await this.doPatchProfile(token, name, username, description, userpic);
      })
    } catch (error) {
      return null;
    }
  }

  async checkAuthorization(): Promise<boolean> {
    try {
      const credentials = localStorage.getItem(this.tokenStorage);
      if (!credentials) {
        runInAction(() => {
          this.isLoading = false;
          this.token = null;
          this.loggedIn = false;
          this.loggedInUser = null;
        })
        return false;
      }

      let token: Token | null = Token.deserialize(credentials);
      let user = await this.getMe(token);
      if (!user && token.isCanBeRefreshed()) {
        token = await this.refreshToken(token);
        if (!token) {
          runInAction(() => {
            this.isLoading = false;
            this.token = null;
            this.loggedIn = false;
            this.loggedInUser = null;
          });
          return false;
        }

        user = await this.getMe(token);
      }

      runInAction(() => {
        this.isLoading = false;
        this.token = token;
        this.loggedIn = true;
        user !== null && (this.loggedInUser = user);
        setTimeout(() => this.checkAuthorization(), this.checkAuthInterval);
      });
      return true;
    } catch (error) {
      console.error('Keychain could not be accessed!', error);
      return false;
    }
  }

  async getItems(username?: string): Promise<WishlistItemResponse[]> {
    if (username) {
      return await this.doGetItemsPublic(username);
    }

    if (!this.loggedIn || !this.token) {
      return [];
    }

    return await this.makeAuthenticatedRequest(async (token: Token) => {
      return await this.doGetItems(token);
    })
  }

  async parseResource(url: string): Promise<ParserResponse | null> {
    if (!this.loggedIn || !this.token) {
      return null;
    }

    return await this.makeAuthenticatedRequest(async (token: Token) => {
      return await this.doParseResource(token, url);
    })
  }

  async createItem(name: string, description: string | null, picture: string | Blob | MediaSource | null, url: string | null): Promise<WishlistItemResponse | null> {
    if (!this.loggedIn || !this.token) {
      return null;
    }

    let uploadedPicture: string | null = null;
    if (picture && typeof picture !== 'string') {
      uploadedPicture = await this.uploadFile(picture, null, false);
    } else {
      uploadedPicture = picture
    }

    return await this.makeAuthenticatedRequest(async (token: Token) => {
      return await this.doCreateItem(token, name, description, uploadedPicture, url);
    })
  }

  async patchItem(uuid: string, name: string, description: string | null, picture: string | Blob | MediaSource | null, url: string | null): Promise<WishlistItemResponse | null> {
    if (!this.loggedIn || !this.token) {
      return null;
    }

    let uploadedPicture: string | null = null;
    if (picture && typeof picture !== 'string') {
      uploadedPicture = await this.uploadFile(picture, null, false);
    } else {
      uploadedPicture = picture
    }

    return await this.makeAuthenticatedRequest(async (token: Token) => {
      return await this.doPatchItem(token, uuid, name, description, uploadedPicture, url);
    })
  }

  async deleteItem(uuid: string): Promise<WishlistItemDeleteResponse | null> {
    if (!this.loggedIn || !this.token) {
      return null;
    }

    return await this.makeAuthenticatedRequest(async (token: Token) => {
      return await this.doDeleteItem(token, uuid);
    })
  }

  async updateSorting(items: string[]): Promise<WishlistItemResponse[]> {
    if (!this.loggedIn || !this.token) {
      return [];
    }

    return await this.makeAuthenticatedRequest(async (token: Token) => {
      return await this.doUpdateSorting(token, items);
    })
  }

  private async doParseResource(token: Token, url: string): Promise<ParserResponse | null> {
    try {
      const {data} = await this.client.post("/parse", {url}, API.authHeader(token))
      return data;
    } catch (error: any | AxiosError) {
      if (axios.isAxiosError(error) && error.response) {
        if (error.response.status === 401) {
          throw new UnauthorizedError();
        }
      }
      return null;
    }
  }

  private async doCreateItem(token: Token, name: string, description: string | null, picture: string | null, url: string | null): Promise<WishlistItemResponse | null> {
    try {
      const {data} = await this.client.post("/wishlist/items", {
        name,
        description,
        image: picture,
        url
      }, API.authHeader(token))
      return data;
    } catch (error: any | AxiosError) {
      if (axios.isAxiosError(error) && error.response) {
        if (error.response.status === 401) {
          throw new UnauthorizedError();
        }
      }
      return null;
    }
  }

  private async doPatchItem(token: Token, uuid: string, name: string, description: string | null, picture: string | null, url: string | null): Promise<WishlistItemResponse | null> {
    try {
      const {data} = await this.client.patch(`/wishlist/items/${uuid}`, {
        name,
        description,
        image: picture,
        url
      }, API.authHeader(token))
      return data;
    } catch (error: any | AxiosError) {
      if (axios.isAxiosError(error) && error.response) {
        if (error.response.status === 401) {
          throw new UnauthorizedError();
        }
      }
      return null;
    }
  }

  private async doDeleteItem(token: Token, uuid: string): Promise<WishlistItemDeleteResponse | null> {
    try {
      const {data} = await this.client.delete(`/wishlist/items/${uuid}`, API.authHeader(token))
      return data;
    } catch (error: any | AxiosError) {
      if (axios.isAxiosError(error) && error.response) {
        if (error.response.status === 401) {
          throw new UnauthorizedError();
        }
      }
      return null;
    }
  }

  private async doUpdateSorting(token: Token, items: string[]): Promise<WishlistItemResponse[]> {
    try {
      const {data} = await this.client.put("/wishlist/items", {items}, API.authHeader(token))
      return data;
    } catch (error: any | AxiosError) {
      if (axios.isAxiosError(error) && error.response) {
        if (error.response.status === 401) {
          throw new UnauthorizedError();
        }
      }
      return [];
    }
  }

  private async doGetItems(token: Token): Promise<WishlistItemResponse[]> {
    try {
      const {data} = await this.client.get("/wishlist/items", API.authHeader(token))
      return data;
    } catch (error: any | AxiosError) {
      if (axios.isAxiosError(error) && error.response) {
        if (error.response.status === 401) {
          throw new UnauthorizedError();
        }
      }
      return [];
    }
  }

  private async doGetItemsPublic(username?: string): Promise<WishlistItemResponse[]> {
    try {
      const {data} = await this.client.get(`/wishlist/${username}/items`)
      return data;
    } catch (error: any | AxiosError) {
      if (axios.isAxiosError(error) && error.response) {
        if (error.response.status === 401) {
          throw new UnauthorizedError();
        }
      }
      return [];
    }
  }

  private async doPatchProfile(token: Token, name: string, username: string, description: string, userpic: string | null): Promise<UserResponse | null> {
    try {
      const payload: { name: string, username: string, description: string, userpic?: string } = {
        name,
        username,
        description
      };
      if (userpic) {
        payload.userpic = userpic;
      }
      console.log(payload);

      const {data} = await this.client.patch('me', payload, API.authHeader(token));

      return data;
    } catch (error: any | AxiosError) {
      if (axios.isAxiosError(error) && error.response) {
        if (error.response.status === 401) {
          throw new UnauthorizedError();
        }
      }
      return null;
    }
  }

  private async doGetToken(email: string, code: string): Promise<TokenResponse | null> {
    try {
      const {status, data} = await this.client.post('token', {email, code});
      if (status !== 200) {
        return null;
      }

      return data;
    } catch (error) {
      return null;
    }
  }

  private async makeAuthenticatedRequest(
    request: (token: Token) => any | null,
  ): Promise<any | null> {
    if (!this.loggedIn || !this.token) {
      return null;
    }

    try {
      return await request(this.token);
    } catch (error: UnauthorizedError | any) {
      if (error instanceof UnauthorizedError) {
        if (!this.token.isCanBeRefreshed()) {
          localStorage.removeItem(this.tokenStorage);
          runInAction(() => {
            this.token = null;
            this.loggedIn = false;
          });

          return null;
        }

        const token = await this.refreshToken(this.token);
        if (!token) {
          localStorage.removeItem(this.tokenStorage);
          runInAction(() => {
            this.token = null;
            this.loggedIn = false;
          });
          return null;
        }

        return await request(token);
      }

      return null;
    }
  }

  private async refreshToken(token: Token | null): Promise<Token | null> {
    if (token === null) {
      return null;
    }

    let refreshedToken = null;
    try {
      refreshedToken = await this.doRefreshToken(token);
    } catch (err) {
      if (err instanceof NetworkError) {
        if (window.location.pathname !== '/') {
          window.location.href = '/';
        }
        return null;
      }
    }
    if (refreshedToken === null) {
      localStorage.removeItem(this.tokenStorage);
      runInAction(() => {
        this.token = null;
        this.loggedIn = false;
        this.loggedInUser = null;
      });
      return null;
    }

    const tokenEntity = new Token(
      refreshedToken.access_token,
      refreshedToken.refresh_token,
      refreshedToken.expiry_at,
      refreshedToken.refresh_token_expiry_at,
    );
    localStorage.setItem(this.tokenStorage, tokenEntity.serialize());
    runInAction(() => {
      this.token = tokenEntity;
      this.loggedIn = true;
    });
    return tokenEntity;
  }

  private async doRefreshToken(token: Token): Promise<TokenResponse | null> {
    try {
      const {status, data} = await this.client.put('token', {
        refresh_token: token.refreshToken,
      });
      if (status !== 200) {
        return null;
      }

      return data;
    } catch (error) {
      if (error instanceof AxiosError && error.code === 'ERR_NETWORK') {
        throw new NetworkError();
      }
      return null;
    }
  }
}
