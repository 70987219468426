import {Box, Button, Flex, Heading, Image, Text} from "@chakra-ui/react";
import {SortableHandle} from "react-sortable-hoc";
import {useState} from "react";
import {TbGridDots} from "react-icons/tb";
import {ExternalLinkIcon} from "@chakra-ui/icons";
import {FiEdit} from "react-icons/fi";
import {useUIManager} from "../hooks/useUIManager";

const Handle = SortableHandle(() => (
  <Box
    bg="blackAlpha.300"
    color="white"
    borderRadius="md"
    aria-label="Drag"
    position="absolute"
    top="0.75rem"
    left="0.75rem"
    cursor="grab"
    p={1}
    zIndex={2}
  >
    <TbGridDots/>
  </Box>
))

const EditBtn = ({onClick}: {onClick: () => any}) => <Box
  bg="blackAlpha.300"
  color="white"
  borderRadius="md"
  aria-label="Drag"
  position="absolute"
  top="0.75rem"
  right="0.75rem"
  cursor="pointer"
  p={1}
  zIndex={2}
  onClick={onClick}
>
  <FiEdit/>
</Box>

export interface CardProps {
  uuid: string;
  image: string;
  title: string;
  description?: string;
  link: string | null;
  hasHandle: boolean;
  editable: boolean;
}

export const Card = ({uuid, image, title, description, link, hasHandle, editable}: CardProps) => {
  const uiManager = useUIManager();
  const [showHandle, toggleHandle] = useState(false);

  return <>
    <Box
      bg="white"
      w="100%"
      maxW={{base: "100%", sm: "100%", md: "49%"}}
      p={3}
      borderRadius="lg"
      position="relative"
      onMouseEnter={() => toggleHandle(true)}
      onMouseLeave={() => toggleHandle(false)}
    >
      {hasHandle && showHandle && <Handle/>}
      {editable && showHandle && <EditBtn onClick={() => uiManager.toggleEditItemModal(true, uuid)}/>}
      <Image
        src={image}
        fallbackSrc={require("../assets/placeholder.png")}
        objectFit="contain"
        w="100%"
        maxH={{base: "200px", md: "300px"}}
        mb={{base: 3, md: 5}}
      />

      <Flex justifyContent="space-between" alignItems="center">
        <Heading as="h2" size={{base: "sm", sm: "sm"}} flexWrap="wrap">{title.length === 35 ? title : `${title.substring(0, 32).trimEnd()}${title.length > 35 ? '...' : ''}`}</Heading>
        {!!link && (<Button as="a" href={link} target="_blank" colorScheme="gray" size="sm">View <ExternalLinkIcon ms={1}/></Button>)}
      </Flex>
      {!!description && (<Text fontSize={{base: "xs", sm: "sm"}} mt={2}>{description}</Text>)}
    </Box>
  </>
}
