import {
  Avatar,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Image,
  Input,
  LinkBox,
  LinkOverlay,
  ModalBody,
  ModalFooter,
  Textarea
} from "@chakra-ui/react";
import {MdOutlineAddAPhoto} from "react-icons/md";
import React, {KeyboardEvent, useState} from "react";
import {Event} from "../../services/types";
import {useFormik} from "formik";
import * as yup from "yup";
import {useAPI} from "../../hooks/useAPI";
import {useUIManager} from "../../hooks/useUIManager";
import { observer } from "mobx-react-lite";

interface User {
  name: string | null
  username: string | null
  description: string | null
}

interface EditFormProps {
  preview: string | null;
  fileRef: React.RefObject<HTMLInputElement>;
  handleClose: () => any;
  changeStep: (step: string) => any;
  setUploadedFile: (file: string) => any;
  setUploadedFileRaw: (file: Blob | MediaSource | null) => any;
  user: User
}

const EditForm = ({
                           preview,
                           fileRef,
                           handleClose,
                           changeStep,
                           setUploadedFile,
                           setUploadedFileRaw,
                           user
                         }: EditFormProps) => {
  const api = useAPI();
  const uiManager = useUIManager();
  const [loading, setLoading] = useState(false);
  const [prevUsername, setPrevUsername] = useState<string>(user.username || '');
  const [timeoutHandle, setTimeoutHandle] = useState<any>(null);

  const patchProfile = (values: { name: string, username: string, description: string | null }) => {
    console.log(values);
    if (loading) {
      return;
    }

    setLoading(true);
    let userpic = null;
    if (preview && preview !== api.loggedInUser?.userpic) {
      userpic = preview || null;
    }

    api.patchProfile(values.name, values.username, values.description || '', userpic)
      .catch(err => {
        throw err;
      })
      .then(value => {
        if (value === null) {
          alert("Cannot update profile due server error. Please try again later...");
          return;
        }
        api.loggedInUser = value;
        uiManager.toggleNeedReloadProfile(true);
        handleClose();
      })
      .finally(() => setLoading(false))
  }

  const formik = useFormik({
    initialValues: {name: user.name || '', username: user.username || '', description: user.description || ''},
    validationSchema: yup.object().shape({
      name: yup.string()
        .required()
        .min(2)
        .max(30),
      username: yup.string()
        .required()
        .min(3)
        .max(20)
        .matches(/^[a-zA-Z0-9-]+$/, "should contain only latin letters, digits and dashes (minus)")
        .test("unique_username", "Username already in use", (username, values) => {
          clearTimeout(timeoutHandle);

          if (prevUsername === username) {
            return new Promise((resolve) => resolve(true));
          }
          return new Promise((resolve) => {
            setTimeoutHandle(setTimeout(() => {
              setPrevUsername(username)
              return api.getUser(username).then(response => resolve(response === null || response.id === api.loggedInUser?.id));
            }, 750))
          })
        }),
      description: yup.string()
        .nullable()
        .max(160)
    }),
    onSubmit: patchProfile
  })

  const chooseFile = () => {
    if (!fileRef.current) {
      console.error("File ref is NULL");
      return;
    }
    fileRef.current.click();
  }
  const loadImagePreview = (event: Event<HTMLInputElement>) => {
    if (!event.target || event.target.files === null) {
      return;
    }

    if (event.target.files.length && event.target.files[0]) {
      setUploadedFileRaw(event.target.files[0]);
      setUploadedFile(URL.createObjectURL(event.target.files[0]));
      changeStep("media");
    }
  }
  const handleKeyPress = async (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      return formik.submitForm();
    }
    return Promise.resolve();
  }

  return <>
    <ModalBody>
      <Input ref={fileRef} type="file" hidden={true} onChange={loadImagePreview}
             accept="image/jpeg,image/png,image/webp"/>

      <Box position="relative" maxW={{base: "96px", sm: "96px"}} m={{base: 1, md: 3}}>
        <LinkBox
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          display="flex"
          justifyContent="center"
          alignItems="center"
          bg="blackAlpha.400"
          borderRadius="full"
          zIndex={9}
        >
          <LinkOverlay href="#" onClick={chooseFile}>
            <Icon as={MdOutlineAddAPhoto} fontSize="3xl" color="white"/>
          </LinkOverlay>
        </LinkBox>
        {!preview && (<Avatar src={preview || ''} borderRadius="full"
                              size="xl" name={user.name?.substring(0, 1)} bg="teal.500"/>)}
        {!!preview && (<Image src={preview || ''} borderRadius="full" objectFit="cover" w="100%"/>)}
      </Box>
      <form id="profile-form" onSubmit={formik.handleSubmit}>
        <Flex flexDirection="column" mt={5} gap={3}>
          <FormControl isInvalid={!!formik.errors.name && formik.touched.name}>
            <FormLabel>Your name</FormLabel>
            <Input id="name" name="name" type="text" placeholder="Your name" readOnly={loading}
                   value={formik.values.name} onChange={formik.handleChange} onKeyDown={handleKeyPress}/>
            <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={(!!formik.errors.username && formik.touched.username)}>
            <FormLabel>Username</FormLabel>
            <Input
              id="username"
              name="username"
              type="text"
              placeholder="Username"
              readOnly={loading}
              value={formik.values.username}
              onChange={formik.handleChange}
              onKeyDown={handleKeyPress}
            />
            <FormErrorMessage>{formik.errors.username}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!formik.errors.description && formik.touched.description}>
            <FormLabel>Description (optional)</FormLabel>
            <Textarea id="description" name="description" placeholder="Profile description"
                      value={formik.values.description} onChange={formik.handleChange}
                      readOnly={loading}/>
            <FormErrorMessage>{formik.errors.description}</FormErrorMessage>
          </FormControl>
        </Flex>
      </form>
    </ModalBody>
    <ModalFooter>
      <Button variant='ghost' mr={2} onClick={handleClose} isDisabled={loading}>Cancel</Button>
      <Button colorScheme="brand" type="submit" form="profile-form"
              isDisabled={loading}>{loading ? 'Saving...' : 'Save'}</Button>
    </ModalFooter>
  </>
}

export default observer(EditForm);
