/* eslint-disable react-hooks/exhaustive-deps */
import {Avatar, Box, Button, Flex, Heading, IconButton, Text, useMediaQuery} from "@chakra-ui/react";
import {BsPlusLg} from "react-icons/bs";
import {useUIManager} from "../hooks/useUIManager";
import {observer} from "mobx-react-lite";
import {useLocation, useNavigate} from "react-router-dom";
import {UserResponse} from "../services/responses";

interface ProfileProps {
  isOwnPage: boolean
  user: UserResponse | null
}

const Profile = ({isOwnPage, user}: ProfileProps) => {
  const [isMd] = useMediaQuery("(min-width: 576px)");
  const uiManager = useUIManager();
  const location = useLocation();
  const navigate = useNavigate();

  const showAddItemModal = () => {
    if (uiManager.addModalShown) {
      return;
    }

    uiManager.toggleAddModal(true);
  }
  const showEditProfileModal = () => {
    if (uiManager.editModalShown) {
      return;
    }

    if (location.pathname !== "/profile/edit") {
      navigate("/profile/edit");
    }
    uiManager.toggleEditModal(true, location.pathname);
  }

  return <Box bg="white" w="100%" p={3} borderRadius="lg" mb={isMd ? 8 : 0}>
    <Flex justifyContent="space-between" alignItems="start">
      <Avatar bg="teal.500" src={user?.userpic || ''} name={(user?.name || '').substring(0, 1)}
              m={{base: 1, md: 3}}
              size={{base: "lg", sm: "xl"}}/>

      {isOwnPage && (<Button size={{base: "sm", sm: "md"}} onClick={showEditProfileModal}>Edit</Button>)}
    </Flex>

    <Box px={{base: 1, md: 3}} mt={3}>
      <Flex justifyContent="start" alignItems="end" gap={3}>
        <Heading size={{base: "sm", sm: "md"}}>{user?.name || ''}</Heading>
        <Text fontSize={{base: "xs", sm: "sm"}} color="gray.500">@{user?.username}</Text>
      </Flex>
      {user?.description && (<Text fontSize={{base: "xs", sm: "md"}} mt={3} whiteSpace="pre-wrap">{user?.description}</Text>)}
    </Box>

    {isOwnPage && !uiManager.addButtonShown && isMd && (<Box marginBottom={-8} pe={3} textAlign="right">
      <IconButton
        size="lg"
        colorScheme="brand"
        borderRadius="full"
        aria-label="Add item"
        icon={<BsPlusLg/>}
        boxShadow="md"
        onClick={showAddItemModal}
      />
    </Box>)}
  </Box>
}

export default observer(Profile);
