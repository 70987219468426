import {makeObservable, observable, runInAction} from 'mobx';

export default class UIManager {
  public addButtonShown: boolean;
  public addModalShown: boolean;
  public editModalShown: boolean;
  public editItemModalShown: boolean;

  public previousPage: string | null;
  public needReloadItems: boolean;

  public needReloadProfile: boolean;

  public editItemId: string | null;

  constructor() {
    makeObservable(this, {
      addButtonShown: observable,
      addModalShown: observable,
      editModalShown: observable,
      editItemModalShown: observable,
      previousPage: observable,
      needReloadItems: observable,
      needReloadProfile: observable,
      editItemId: observable,
    })
    this.addButtonShown = false;
    this.addModalShown = false;
    this.editModalShown = false;
    this.editItemModalShown = false;
    this.previousPage = null;
    this.needReloadItems = true;
    this.needReloadProfile = true;
    this.editItemId = null;
  }

  toggleAddButton(show: boolean) {
    runInAction(() => {
      this.addButtonShown = show;
    })
  }

  toggleAddModal(show: boolean) {
    runInAction(() => {
      this.addModalShown = show;
    })
  }

  toggleEditModal(show: boolean, previousPage: string | null = null) {
    runInAction(() => {
      this.editModalShown = show;
      this.previousPage = previousPage;
    })
  }

  toggleEditItemModal(show: boolean, editItemId: string | null = null) {
    runInAction(() => {
      this.editItemModalShown = show;
      this.editItemId = editItemId;
    })
  }

  toggleNeedReloadItems(reload: boolean) {
    runInAction(() => {
      this.needReloadItems = reload;
    })
  }

  toggleNeedReloadProfile(reload: boolean) {
    runInAction(() => {
      this.needReloadProfile = reload;
    })
  }
}
