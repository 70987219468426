/* eslint-disable react-hooks/exhaustive-deps */
import Profile from "../components/Profile";
import {
  Box,
  Button,
  CircularProgress,
  Flex,
  Heading,
  Icon,
  Portal,
  Spinner,
  Text,
  useMediaQuery,
  useToast,
  VStack
} from "@chakra-ui/react";
import {Card} from "../components/Card";
import {FabButton} from "../components/FabButton";
import {ShareDialogue} from "../components/ShareDialogue";
import AddItemModal from "../components/AddItemModal";
import {SortableContainer, SortableContainerProps, SortableElement, SortableElementProps} from "react-sortable-hoc";
import {useEffect, useState} from "react";
import {ItemType} from "../services/types";
import {arrayMoveImmutable} from 'array-move';
import EditProfileModal from "../components/EditProfileModal";
import {Link as RouterLink, useLocation, useNavigate} from "react-router-dom";
import {useUIManager} from "../hooks/useUIManager";
import {useAPI} from "../hooks/useAPI";
import {observer} from "mobx-react-lite";
import {UserResponse} from "../services/responses";
import EditItemModal from "../components/EditItemModal";
import {GiSadCrab} from "react-icons/gi";

interface SortableItemProps extends SortableElementProps {
  value: ItemType
  showHandle: boolean
  showEditButton: boolean
}

interface SortableListProps extends SortableContainerProps {
  items: ItemType[]
  disabled: boolean
}

const SortableItem = SortableElement<SortableItemProps>(({value, showHandle, showEditButton}: SortableItemProps) => {
  return <Card
    uuid={value.uuid}
    image={value.image || ''}
    title={value.name}
    description={value.description ?? undefined}
    link={value.link}
    hasHandle={showHandle}
    editable={showEditButton}
  />
})

const SortableList = SortableContainer<SortableListProps>(({items, disabled}: SortableListProps) => {
  return <Flex my={5} justifyContent="space-between" flexWrap="wrap" gap={3}>
    {items.map((value: ItemType, index: number) => (
      <SortableItem key={value.uuid} value={value} index={index}
                    disabled={disabled} showHandle={!disabled} showEditButton={!disabled}/>
    ))}
  </Flex>
});

const ProfilePage = () => {
  const uiManager = useUIManager();
  const location = useLocation();
  const navigate = useNavigate();
  const api = useAPI();
  const toast = useToast()

  const [isMd] = useMediaQuery("(min-width: 576px)");
  const [movable, setMovable] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [items, setItems] = useState<ItemType[]>([]);

  const [updateTimeout, setUpdateTimeout] = useState<NodeJS.Timeout | number | null>(null);
  const [isOwnProfile, setIsOwnProfile] = useState(api.loggedIn && (location.pathname.startsWith("/profile") || location.pathname === `/${api.loggedInUser?.username}`));
  const [user, setUser] = useState<UserResponse | null>(null)

  useEffect(() => {
    api.checkAuthorization()
      .then((loggedIn: boolean) => {
        if (!loggedIn && location.pathname.startsWith("/profile")) {
          return null;
        }

        if (location.pathname.startsWith("/profile") && uiManager.needReloadProfile) {
          return api.getUser(api.loggedInUser?.username || "");
        }

        if (location.pathname.startsWith("/profile")) {
          return api.loggedInUser;
        }

        return api.getUser(location.pathname.substring(1));
      })
      .then((user) => {
        setUser(user);
        setIsOwnProfile(api.loggedIn && (location.pathname.startsWith("/profile") || location.pathname === `/${api.loggedInUser?.username}`));

        if (!(api.loggedIn && (location.pathname.startsWith("/profile") || location.pathname === `/${api.loggedInUser?.username}`))) {
          setIsOwnProfile(false);
          setMovable(false);
        }

        if (!user) {
          if (location.pathname.startsWith("/profile")) {
            navigate("/signin");
            return user;
          }

          return user;
        }

        if (location.pathname === '/profile/edit' && !uiManager.editModalShown) {
          uiManager.toggleEditModal(true);
        }

        return user;
      })
      .then((user) => {
        return api.getItems(user === null || isOwnProfile ? undefined : user?.username)
      })
      .then((items_) => {
          setLoaded(true);
          setItems(items_)
        }
      )
      .finally(() => {
        uiManager.toggleNeedReloadItems(false);
      })
  }, [uiManager.needReloadItems, uiManager.needReloadProfile, location.pathname]);

  const onSortStart = () => {
    if (!api.loggedIn || !movable) {
      return;
    }

    if (updateTimeout !== null) {
      clearTimeout(updateTimeout);
    }
  }
  const onSortEnd = ({oldIndex, newIndex}: { oldIndex: number, newIndex: number }) => {
    if (!api.loggedIn || !movable) {
      return;
    }

    if (updateTimeout !== null) {
      clearTimeout(updateTimeout);
    }

    setItems(arrayMoveImmutable(items, oldIndex, newIndex))
    setUpdateTimeout(setTimeout(() => {
      setMovable(false);
      const t = toast({
        position: 'top-right',
        duration: null,
        render: () => (
          <Box color="white" p={3} bg="green.300">
            <CircularProgress size="25px" isIndeterminate color='white' me={2}/>
            Saving...
          </Box>
        )
      })
      api.updateSorting(arrayMoveImmutable(items, oldIndex, newIndex).map(i => i.uuid))
        .finally(() => {
          toast.close(t);
          setMovable(true)
        })
    }, 750));
  }

  return <>
    {loaded && user === null && (
      <>
        <VStack gap={2}>
          <Icon as={GiSadCrab} boxSize="64px"/>
          <Heading textAlign="center">Page not found</Heading>
          <Text textAlign="center" fontSize="lg" textColor="gray.500">Oh no, we don't seem to have the page you're
            looking for.</Text>
          <Button as={RouterLink} to="/">Return to homepage</Button>
        </VStack>
      </>
    )}

    {loaded && user !== null && (<>
      <Profile isOwnPage={isOwnProfile} user={user}/>

      <>
        {!!items.length && (
          <>
            <SortableList items={items} axis="xy" useDragHandle={true} onSortStart={onSortStart} onSortEnd={onSortEnd}
                          disabled={!movable} disableAutoscroll={false}/>
            {isOwnProfile && (<ShareDialogue url={`https://wishlists.cc/${user?.username}`}/>)}
          </>
        )}
        {!items.length && (
          <>
            <Box textAlign="center" mt="3rem">
              <VStack color="gray.500" fontSize={{base: "xs", md: "sm"}} gap={0}>
                <Text fontSize={{base: "36px", md: "48px"}}>¯\_(ツ)_/¯</Text>
                <Text>{isOwnProfile ? 'Your' : 'Their'} wishlist is empty.</Text>
                {isOwnProfile && <Text>It's time to add something to it.</Text>}
              </VStack>
            </Box>
          </>
        )}
        {isOwnProfile && !isMd && <FabButton/>}
      </>
    </>)}

    {!loaded && (<>
      <Flex justifyContent="center" alignItems="center" flexGrow={1} mt="3rem">
        <Spinner size="xl"/>
      </Flex>
    </>)}

    <Portal>
      <AddItemModal/>
      <EditItemModal/>
      <EditProfileModal/>
    </Portal>
  </>
}

export default observer(ProfilePage)
