import {useRouteError} from "react-router-dom";
import {Container, Heading, Icon, Text, VStack} from "@chakra-ui/react";
import {FaSadCry} from "react-icons/fa";
import Navbar from "../components/Navbar";
import {Footer} from "../components/Footer";

export const ErrorPage = () => {
  const error: any = useRouteError();

  return (
    <Container maxW="container.lg" py="1rem" position="relative">
      <Navbar/>
      <VStack gap={2}>
        <Icon as={FaSadCry} boxSize="64px"/>
        <Heading textAlign="center">Ooops, error</Heading>
        <Text textAlign="center" fontSize="lg" textColor="gray.500">{error.statusText || error.message}</Text>
      </VStack>
      <Footer/>
    </Container>
  )
}
