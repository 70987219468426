import {ChevronDownIcon} from "@chakra-ui/icons";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent, AlertDialogFooter, AlertDialogHeader,
  AlertDialogOverlay,
  Avatar, Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Portal,
  useMediaQuery, useToast
} from "@chakra-ui/react";
import {Link as RouterLink} from "react-router-dom";
import {useAPI} from "../hooks/useAPI";
import React, {useState} from "react";

interface SmallProfileProps {
  compact?: boolean;
}

export const SmallProfile = ({compact}: SmallProfileProps) => {
  const api = useAPI();
  const loggedInUser = api.loggedInUser;
  const [isMd] = useMediaQuery("(min-width: 576px)");

  const toast = useToast()

  const [showConfirmation, toggleConfirmation] = useState(false)
  const cancelRef = React.useRef<HTMLButtonElement>(null);

  const logout = () => {
    toggleConfirmation(false);
    api.logout().then(() => {
      toast({
        position: 'top-right',
        render: () => (
          <Box color="white" p={3} bg="green.300">
            Successfully logged out
          </Box>
        )
      });

      setTimeout(() => window.location.reload(), 1000);
    })
  };

  return <>
    <Menu>
      {({isOpen}) => (<>
        <MenuButton
          isActive={isOpen}
          as={Button}
          variant="ghost"
          height="fit-content"
          py="0.5rem"
          rightIcon={<ChevronDownIcon/>}
        >
          <Flex justifyContent="start" alignItems="center" gap={3}>
            <Avatar bg="teal.500" name={(loggedInUser?.name || '').substring(0, 1)} src={loggedInUser?.userpic || ''}/>
            {!compact && isMd ? loggedInUser?.name : ''}
          </Flex>
        </MenuButton>
        <MenuList minWidth="240px">
          <MenuItem as={RouterLink} to="/profile">Profile</MenuItem>
          <MenuDivider/>
          <MenuItem onClick={() => toggleConfirmation(true)}>Logout</MenuItem>
        </MenuList>
      </>)}
    </Menu>
    <Portal>
      <AlertDialog
        motionPreset='slideInBottom'
        onClose={() => toggleConfirmation(false)}
        isOpen={showConfirmation}
        isCentered
        leastDestructiveRef={cancelRef}>
        <AlertDialogOverlay/>

        <AlertDialogContent>
          <AlertDialogHeader>Log out?</AlertDialogHeader>
          <AlertDialogCloseButton/>
          <AlertDialogBody>
            Are you sure you want to log out?
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={() => toggleConfirmation(false)}>
              Don't log out
            </Button>
            <Button colorScheme='brand' ml={3} onClick={logout}>
              Log out
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </Portal>
  </>
}
