import {
  Box,
  Button,
  Flex,
  FormControl,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  Tooltip
} from "@chakra-ui/react";
import {RxCopy} from "react-icons/rx";
import {BsFacebook, BsLinkedin, BsTwitter} from "react-icons/bs";
import {useState} from "react";
import {Link as RouterLink} from "react-router-dom";

import copy from 'copy-to-clipboard';
import {BiLinkExternal} from "react-icons/bi";

interface ShareDialogueProps {
  url: string;
}

export const ShareDialogue = ({url}: ShareDialogueProps) => {
  const [copied, setCopied] = useState(false);
  const doCopy = () => {
    setCopied(true);
    copy(url);
    setTimeout(() => setCopied(false), 1500);
  }

  return <Box bg="white" w="100%" p={3} borderRadius="lg">
    <Flex flexDirection="column" alignItems="center">
      <Heading m={5} size={{base: "md", sm: "lg"}}>Share your wishlist</Heading>
      <Text fontSize={{base: "xs", sm: "md"}} textAlign="center">Use the following URL to share this wishlist with your
        friends and family.</Text>

      <Flex flexDirection="row" justifyContent="center" alignItems="center" mt={{base: 5, sm: 10}} w="100%" gap={2}>
        <FormControl maxW="500px" w="100%">
          <InputGroup>
            <Input
              type="text"
              readOnly={true}
              fontSize={{base: "xs", sm: "md"}}
              color="gray.500"
              value={url}
            />
            <InputRightElement children={<IconButton as={RouterLink} variant="ghost" size={{base: "xs", sm: "sm"}} me={1} to={url} icon={<BiLinkExternal/>} aria-label="Go"/>}/>
          </InputGroup>
        </FormControl>

        <Tooltip label="Copied to clipboard" placement="right" isOpen={copied}>
          <Button variant="ghost" onClick={doCopy}>
            <RxCopy/>
          </Button>
        </Tooltip>
      </Flex>

      <Flex gap={2} my={5}>
        <IconButton aria-label="Linkedin" icon={<BsLinkedin/>} fontSize="24px" color="linkedin.700" variant="ghost"/>
        <IconButton aria-label="Linkedin" icon={<BsFacebook/>} fontSize="24px" color="facebook.600" variant="ghost"/>
        <IconButton aria-label="Linkedin" icon={<BsTwitter/>} fontSize="24px" color="twitter.600" variant="ghost"/>
      </Flex>
    </Flex>
  </Box>
}
