import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  Input,
  PinInput,
  PinInputField,
  Text,
  VStack
} from "@chakra-ui/react";
import ReCAPTCHA from "react-google-recaptcha";
import {useEffect, useState} from "react";
import {useAPI} from "../hooks/useAPI";
import {Field, Formik} from "formik";
import * as yup from "yup";
import {useNavigate} from "react-router-dom";
import {observer} from "mobx-react-lite";

const AuthPage = () => {
  const api = useAPI();
  const navigate = useNavigate();

  const [step, setStep] = useState("credentials");
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState<string>("");

  useEffect(() => {
    if (api.loggedIn) {
      navigate("/profile");
    }
  }, [navigate, api.loggedIn])

  const doAuth = (values: {email: string, captcha: string}) => {
    setLoading(true);
    api.auth(values.email, values.captcha)
      .catch(err => {
        console.error(err)
        throw err;
      })
      .then(value => {
        if (value !== null) {
          setEmail(values.email)
          setStep("pin")
        }
      })
      .finally(() => setLoading(false))
  }

  const onPinChange = async (value: string) => {
    if (value.length === 6) {
      console.log("Pin complete", email, value);
      try {
        setLoading(true);
        if (await api.getToken(email, value)) {
          window.location.replace("/profile");
          return <Text>You will be redirected in a moment</Text>
        }
      } catch (e: any) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    }
  }

  return <Box bg="white" p={3} textAlign="center">
    <Heading mt={5} size={{base: "md", sm: "lg"}}>
      {step === "credentials" ? "Sign in" : "Check your mailbox"}
    </Heading>
    <Text mx="auto" mt={3} size={{base: "xs", sm: "sm"}} color="gray.500" maxW="470px" w="100%">
      {step === "credentials"
        ? "Fill the following form to start creating your wishlists immediately. If you don't have an account yet, we'll create it for you."
        : `We've sent a 6-character code to ${email}. The code expires shortly, so please enter it soon.`
      }
    </Text>

    <Box mt="3rem" mx="auto" w={{base: '100%', sm: '75%', md: '60%'}}>
      {step === "credentials" && (
        <Formik
          initialValues={{ email: '', captcha: ''}}
          onSubmit={doAuth}
          validationSchema={
            yup.object().shape({
              email: yup.string().required().email(),
              captcha: yup.string().required()
            })
          }
        >
          {({ handleSubmit, errors, touched, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <FormControl textAlign="left" isInvalid={!!errors.email && touched.email}>
                <FormLabel>Email</FormLabel>
                <Field
                  id="email"
                  name="email"
                  as={Input}
                  type="email"
                  placeholder="your-email@example.com"
                  readOnly={loading}
                />
                <FormErrorMessage>{errors.email}</FormErrorMessage>
                <FormHelperText>We'll never share your email.</FormHelperText>
              </FormControl>

              <FormControl mt={5} isInvalid={!!errors.captcha && touched.captcha}>
                <ReCAPTCHA
                  sitekey="6LcabLIkAAAAABfLA66ymnaOeahDa2p-fgQvf5tK"
                  onChange={(value: string | null) => {setFieldValue("captcha", value)}}/>
                <FormErrorMessage>{errors.captcha}</FormErrorMessage>
              </FormControl>

              <Button
                type="submit"
                my={5}
                colorScheme="brand"
                w="40%"
                disabled={loading}
              >
                {loading ? 'Please wait...' : 'Sign in'}
              </Button>
            </form>
          )}
        </Formik>
      )}
      {step === "pin" && (
        <>
          <VStack justifyContent="center" mb={5}>
            <HStack>
              <PinInput otp size="lg" onChange={onPinChange} isDisabled={loading}>
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
              </PinInput>
            </HStack>
          </VStack>
        </>
      )}
    </Box>
  </Box>
}

export default observer(AuthPage);
