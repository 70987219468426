/* eslint-disable react-hooks/exhaustive-deps */
import {observer} from "mobx-react-lite";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  SkeletonText,
  Textarea,
  useMediaQuery,
  useToast
} from "@chakra-ui/react";
import React, {useCallback, useEffect, useState} from "react";
import {useUIManager} from "../hooks/useUIManager";
import {FileForm} from "./AddItemModal/FileForm";
import {useFormik} from "formik";
import * as yup from "yup";
import {useAPI} from "../hooks/useAPI";
import {WishlistItemResponse} from "../services/responses";

const EditItemModal = () => {
  const api = useAPI();
  const uiManager = useUIManager();
  const [isLg] = useMediaQuery("(min-width: 992px)");
  const [initialized, setInitialized] = useState(false)
  const [loading, setLoading] = useState(false)
  const [picture, setPicture] = useState('');
  const [uploadedFile, setUploadedFile] = useState<Blob | MediaSource | null>(null);
  const [item, setItem] = useState<WishlistItemResponse | null>(null);

  const toast = useToast()

  const [showConfirmation, toggleConfirmation] = useState(false)
  const cancelRef = React.useRef<HTMLButtonElement>(null);

  const updateItem = ({name, description, url}: { name: string, description: string, url: string }) => {
    if (loading || !item) {
      return;
    }

    setLoading(true);
    api.patchItem(item.uuid, name, description || null, (picture.startsWith('https://files.mznx.dev/') ? picture : uploadedFile) || null, url || null)
      .then((response) => {
        if (response === null) {
          toast({
            position: 'top-right',
            render: () => (
              <Box color="white" p={3} bg="red.300">
                Unexpected server error
              </Box>
            )
          });
          return;
        }
        uiManager.toggleNeedReloadItems(true);
        uiManager.toggleEditItemModal(false, null);
      })
      .finally(() => {
        setLoading(false);
      })
  }
  const formik = useFormik({
    initialValues: {name: item?.name || '', description: item?.description || '', url: item?.link || ''},
    onSubmit: updateItem,
    validationSchema: yup.object().shape({
      name: yup.string()
        .required()
        .min(1)
        .max(64),
      description: yup.string()
        .nullable()
        .min(1)
        .max(255),
      url: yup.string()
        .nullable()
        .url(),
    })
  });
  const initializeForm = useCallback((item: WishlistItemResponse) => {
    setPicture(item.image || '');
    formik.setFieldValue('name', item.name || '')
    formik.setFieldValue('description', item.description || '')
    formik.setFieldValue('url', item.link || '')
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (uiManager.editItemId === null) {
      uiManager.toggleEditItemModal(false);
      return;
    }

    setLoading(true);
    setInitialized(false);
    api.getItem(uiManager.editItemId)
      .then(response => {
        if (response === null) {
          uiManager.toggleEditItemModal(false);
          return;
        }

        setItem(response);
        initializeForm(response);
      })
      .finally(() => {
        setLoading(false);
      })
  }, [api, initializeForm, uiManager, uiManager.editItemId])

  const deleteItem = () => {
    if (uiManager.editItemId === null || !item) {
      uiManager.toggleEditItemModal(false);
      return;
    }

    setLoading(true);
    api.deleteItem(item.uuid)
      .then(response => {
        if (response === null) {
          toast({
            position: 'top-right',
            render: () => (
              <Box color="white" p={3} bg="red.300">
                Cannot delete {item.name} due server error
              </Box>
            )
          });
          return;
        }

        toast({
          position: 'top-right',
          render: () => (
            <Box color="white" p={3} bg="green.300">
              {item.name} successfully deleted
            </Box>
          )
        });
        clearData();
        toggleConfirmation(false);
        uiManager.toggleEditItemModal(false);
        uiManager.toggleNeedReloadItems(true);
      })
      .finally(() => {
        setLoading(false);
      })
  }

  const clearData = () => {
    setPicture('');
    setUploadedFile(null);
    formik.setFieldValue('name', '')
    formik.setFieldValue('description', '')
    formik.setFieldValue('url', '')
  }

  const onClose = () => {
    if (picture !== item?.image
      || formik.values.name !== (item?.name || '')
      || formik.values.description !== (item?.description || '')
      || formik.values.url !== (item?.link || '')
    ) {
      if (window.confirm('Do you really want to close the window? All unsaved changes will be lost.')) {
        clearData();
        uiManager.toggleEditItemModal(false);
        return;
      }
    }

    clearData();
    uiManager.toggleEditItemModal(false);
  }

  return <>
    <Modal
      isCentered
      isOpen={uiManager.editItemModalShown}
      onClose={onClose}
      size={isLg ? '2xl' : 'full'}
    >
      <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)"/>
      <ModalContent>
        <ModalHeader>Edit item</ModalHeader>
        <ModalCloseButton/>
        <ModalBody>
          <Box mb={3}>
            {initialized && (<form id="edit-item" onSubmit={formik.handleSubmit}>
              <Flex flexDirection="column" flexGrow={1}>
                <FileForm w={{base: "100%", md: "240px"}} mb={3} picture={picture}
                          setPicture={setPicture} setUploadedFile={setUploadedFile}/>

                <FormControl mb={3} isInvalid={!!formik.errors.name && formik.touched.name}>
                  <FormLabel>Name</FormLabel>
                  <Input id="name" name="name"
                         value={formik.values.name}
                         onChange={formik.handleChange}
                         type="text"
                         placeholder="Apple Watch Ultra"
                         readOnly={loading}
                  />
                  <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
                </FormControl>

                <FormControl mb={3} isInvalid={!!formik.errors.description && formik.touched.description}>
                  <FormLabel>Description (optional)</FormLabel>
                  <Textarea id="description" name="description"
                            value={formik.values.description}
                            onChange={formik.handleChange}
                            placeholder="Here you leave a note what color you want or which size you need..."
                            readOnly={loading}/>
                  <FormErrorMessage>{formik.errors.description}</FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={!!formik.errors.url && formik.touched.url}>
                  <FormLabel>Link to store (optional)</FormLabel>
                  <Input id="url" name="url"
                         value={formik.values.url}
                         onChange={formik.handleChange}
                         placeholder="Here you can add a link to the product in the online store"
                         readOnly={loading}/>
                  <FormErrorMessage>{formik.errors.url}</FormErrorMessage>
                </FormControl>
              </Flex>
            </form>)}
            {!initialized && (
              <Box>
                <Skeleton w={{base: "100%", md: "240px"}} h={{base: "220px", md: "180px"}}/>
                <SkeletonText mt='4' noOfLines={4} spacing='4' skeletonHeight='2'/>
              </Box>
            )}
          </Box>
        </ModalBody>
        <ModalFooter justifyContent="space-between">
          <Button variant="outline" isDisabled={loading} colorScheme="red" justifySelf="start" onClick={() => toggleConfirmation(true)}>Delete</Button>
          <Flex>
            <Button variant='ghost' mr={2} onClick={onClose} isDisabled={loading}>Cancel</Button>
            <Button type="submit" form="edit-item" colorScheme="brand"
                    isDisabled={loading}>{loading ? 'Please wait...' : 'Save changes'}</Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
    <AlertDialog
      motionPreset='slideInBottom'
      onClose={() => toggleConfirmation(false)}
      isOpen={showConfirmation}
      isCentered
     leastDestructiveRef={cancelRef}>
      <AlertDialogOverlay />

      <AlertDialogContent>
        <AlertDialogHeader>Delete {item?.name}?</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          Are you sure you want to delete <b>{item?.name}</b>? This action cannot be undone.
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button ref={cancelRef} onClick={() => toggleConfirmation(false)}>
            Don't delete
          </Button>
          <Button colorScheme='red' ml={3} onClick={deleteItem}>
            Delete
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  </>
}

export default observer(EditItemModal);
