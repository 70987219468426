import {Box, Button, Container, Flex, Link, Portal, useMediaQuery} from "@chakra-ui/react";
import {Link as RouterLink, useLocation} from "react-router-dom";
import {SmallProfile} from "./SmallProfile";
import {useEffect} from "react";
import {useUIManager} from "../hooks/useUIManager";
import {observer} from "mobx-react-lite";
import {useAPI} from "../hooks/useAPI";

const Navbar = () => {
  const api = useAPI();
  const [isMd] = useMediaQuery("(min-width: 576px)");
  const [isLg] = useMediaQuery("(min-width: 1024px)");
  const uiManager = useUIManager();
  const location = useLocation();

  const isOwnProfile = location.pathname.startsWith("/profile") || location.pathname === `/${api.loggedInUser?.username}`

  const onClick = () => {
    if (uiManager.addModalShown) {
      return;
    }

    uiManager.toggleAddModal(true);
  }

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 100) {
        uiManager.toggleAddButton(true);
      }

      if (window.scrollY < 80) {
        uiManager.toggleAddButton(false);
      }
    };
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, {passive: true});

    return () => window.removeEventListener('scroll', onScroll);
  }, [uiManager]);

  return <>
    <Box
      px={0}
      py={1}
      mb={3}
      w="100%"
    >
      <Flex justifyContent="space-between" alignItems="center">
        <Link as={RouterLink} to="/" className="logo">
          Wishlists
        </Link>

        {!api.isLoading && api.loggedIn && (<SmallProfile/>)}
        {!api.isLoading && !api.loggedIn && (<Button to="/signin" as={RouterLink} colorScheme="brand">Sign in</Button>)}
      </Flex>
    </Box>
    {uiManager.addButtonShown && isMd && (<Portal>
      <Box
        bg='white'
        py={1}
        mb={3}
        position='fixed'
        w="100%"
        left={isLg ? undefined : 0}
        top={0}
        zIndex={9}
        boxShadow="md"
      >
        <Container maxW="container.lg" px={{base: 0, sm: '1.5rem'}}>
          <Flex justifyContent="space-between" alignItems="center">
            <Link as={RouterLink} to="/" className={`logo ${uiManager.addButtonShown ? 'small' : ''}`}>
              Wishlists
            </Link>

            {!api.isLoading && api.loggedIn && (
              <Flex justifyContent="space-between" alignItems="center" gap={3}>
                {isOwnProfile && (<Button colorScheme="brand" onClick={onClick}>
                  Add Item</Button>)}
                <SmallProfile compact={uiManager.addButtonShown}/>
              </Flex>
            )}
            {!api.isLoading && !api.loggedIn && (<Button to="/signin" as={RouterLink} colorScheme="brand">Sign in</Button>)}
          </Flex>
        </Container>
      </Box>
    </Portal>)}
  </>
}

export default observer(Navbar);
