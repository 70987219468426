import {createBrowserRouter, createRoutesFromElements, Route, RouterProvider} from "react-router-dom";
import React from "react";
import {Root} from "./Root";
import {ErrorPage} from "./pages/ErrorPage";
import {IndexPage} from "./pages/IndexPage";
import AuthPage from "./pages/AuthPage";
import ProfilePage from "./pages/ProfilePage";
import {observer} from "mobx-react-lite";

const App = () => {
  const router = createBrowserRouter(createRoutesFromElements(
    <Route path="/" element={<Root/>}>
      <Route errorElement={<ErrorPage/>}>
        <Route index element={<IndexPage/>}/>
        <Route path="/signin" element={<AuthPage/>}/>
        <Route path="/profile" element={<ProfilePage/>}/>
        <Route path="/profile/edit" element={<ProfilePage/>}/>
        <Route path="/:slug" element={<ProfilePage/>}/>
      </Route>
    </Route>
  ))

  return (
    <RouterProvider router={router} fallbackElement={<div>Cannot load router</div>}/>
  )
}

export default observer(App);
