import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useMediaQuery
} from "@chakra-ui/react";
import {useUIManager} from "../hooks/useUIManager";
import {observer} from "mobx-react-lite";
import React, {FormEvent, useState} from "react";
import {FileForm} from "./AddItemModal/FileForm";
import {useAPI} from "../hooks/useAPI";
import * as yup from "yup";
import {useFormik} from "formik";

let timeout: string | number | NodeJS.Timeout | undefined;

const AddItemModal = () => {
  const api = useAPI();
  const [isLg] = useMediaQuery("(min-width: 992px)");
  const uiManager = useUIManager();
  const urlRef = React.useRef(null);
  const [loading, setLoading] = useState(false);
  const [picture, setPicture] = useState('');
  const [uploadedFile, setUploadedFile] = useState<Blob | MediaSource | null>(null);
  const [url, setUrl] = useState('');

  const createItem = ({name, description}: { name: string, description: string }) => {
    if (loading) {
      return;
    }

    setLoading(true);
    api.createItem(name, description || null, (picture.startsWith('https://files.mznx.dev/') ? picture : uploadedFile) || null, url || null)
      .then((response) => {
        uiManager.toggleNeedReloadItems(true);
        uiManager.toggleAddModal(false);
      })
      .finally(() => {
        setLoading(false);
      })
  }
  const formik = useFormik({
    initialValues: {name: '', description: ''},
    onSubmit: createItem,
    validationSchema: yup.object().shape({
      name: yup.string()
        .required()
        .min(1)
        .max(64),
      description: yup.string()
        .nullable()
        .min(1)
        .max(255)
    })
  });

  const onClose = () => {
    if (!uiManager.addModalShown) {
      return;
    }

    formik.setFieldValue('name', '');
    formik.setFieldValue('description', '');
    setPicture('');
    setUploadedFile(null);
    setUrl('');
    uiManager.toggleAddModal(false);
  }

  const onUrlChange = (event: FormEvent<HTMLInputElement>) => {
    const resourceUrl: string = (event.target as HTMLInputElement).value;
    setUrl(resourceUrl);

    setPicture('');
    formik.setFieldValue('name', '');
    formik.setFieldValue('description', '');
    if (timeout) {
      clearTimeout(timeout);
      timeout = undefined;
    }
    if (!resourceUrl.length) {
      formik.setFieldValue('name', '');
      formik.setFieldValue('description', '');
      return;
    }
    setLoading(true);
    timeout = setTimeout(() => {
      api.parseResource(resourceUrl)
        .then(response => {
          if (response === null) {
            return response;
          }

          formik.setFieldValue('name', response.name.substring(0, 64));
          formik.setFieldValue('description', response.description.substring(0, 255));
          setPicture(response.picture);
          return response;
        })
        .finally(() => {
          formik.values.name === 'Please wait...' && formik.setFieldValue('name', '');
          formik.values.description === 'Please wait...' && formik.setFieldValue('description', '');
          setLoading(false);
        })
    }, 750);
  }

  return <Modal
    initialFocusRef={urlRef}
    isCentered
    isOpen={uiManager.addModalShown}
    onClose={onClose}
    size={isLg ? '2xl' : 'full'}
  >
    <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)"/>
    <ModalContent>
      <ModalHeader>Add an item to wishlist</ModalHeader>
      <ModalCloseButton/>
      <ModalBody>
        <Box mb={3}>
          <form id="add-item" onSubmit={formik.handleSubmit}>
            <FormControl>
              <FormLabel>Link to product or service you want in the online store</FormLabel>
              <Input
                ref={urlRef}
                placeholder="https://www.apple.com/shop/buy-watch/apple-watch-ultra"
                value={url}
                onChange={onUrlChange}
                readOnly={loading}
              />
            </FormControl>

            <Text textAlign="center" color="gray.500" my={5}><b>or</b></Text>

            <Flex flexDirection="row" flexWrap="wrap" alignItems="center">
              <FileForm w={{base: "100%", md: "240px"}} me={{base: 0, md: 5}} picture={picture}
                        setPicture={setPicture} setUploadedFile={setUploadedFile}/>

              <Flex flexDirection="column" flexGrow={1}>
                <FormControl mb={3} isInvalid={!!formik.errors.name && formik.touched.name}>
                  <FormLabel>Name</FormLabel>
                  <Input id="name" name="name"
                         value={formik.values.name}
                         onChange={formik.handleChange}
                         type="text"
                         placeholder="Apple Watch Ultra"
                         readOnly={loading}
                  />
                  <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={!!formik.errors.description && formik.touched.description}>
                  <FormLabel>Description (optional)</FormLabel>
                  <Textarea id="description" name="description"
                            value={formik.values.description}
                            onChange={formik.handleChange}
                            placeholder="Here you leave a note what color you want or which size you need..."
                            readOnly={loading}/>
                  <FormErrorMessage>{formik.errors.description}</FormErrorMessage>
                </FormControl>
              </Flex>
            </Flex>
          </form>
        </Box>
      </ModalBody>
      <ModalFooter>
        <Button variant='ghost' mr={2} onClick={onClose} isDisabled={loading}>Cancel</Button>
        <Button type="submit" form="add-item" colorScheme="brand"
                isDisabled={loading}>{loading ? 'Please wait...' : 'Add item'}</Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
}

export default observer(AddItemModal);
