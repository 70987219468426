import {Container} from "@chakra-ui/react";
import {Outlet} from "react-router-dom";
import Navbar from "./components/Navbar";
import {Footer} from "./components/Footer";

export const Root = () => {
  return (<Container maxW="container.lg" py="1rem" position="relative">
    <Navbar/>
    <Outlet/>
    <Footer/>
  </Container>)
}
