import {
  Box,
  Button,
  ModalBody,
  ModalFooter,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack
} from '@chakra-ui/react'
import React, {useCallback, useState} from "react";
import Cropper from 'react-easy-crop'
import {useAPI} from "../../hooks/useAPI";

interface CropImageProps {
  uploadedFile: string | null;
  uploadedFileRaw: Blob | MediaSource | null;
  changeStep: (step: string) => any;
  changePreview: (preview: string) => any;
}

export const CropImage = ({uploadedFile, uploadedFileRaw, changeStep, changePreview}: CropImageProps) => {
  const api = useAPI();
  const [crop, setCrop] = useState({x: 0, y: 0})
  const [zoom, setZoom] = useState(1)
  const [requestInProgress, setRequestInProgress] = useState(false)
  const [croppedArea, setCroppedArea] = useState<any>({});

  const onCropComplete = useCallback((croppedArea: any, croppedAreaPixels: any) => {
    setCroppedArea(croppedAreaPixels);
  }, [])

  const onSave = async () => {
    if (requestInProgress) {
      return;
    }

    if (uploadedFileRaw) {
      setRequestInProgress(true);
      try {
        const fileUrl = await api.uploadFile(uploadedFileRaw, croppedArea, true);
        if (fileUrl === null) {
          alert("Cannot upload image. Please try again later...")
          return;
        }

        changePreview(fileUrl);
        changeStep("profile");
      } finally {
        setRequestInProgress(false);
      }
    }
  }


  return <>
    <ModalBody>
      <Box position="relative" height={{base: "320px", md: "450px"}}>
        <Cropper
          image={uploadedFile || ''}
          crop={crop}
          zoom={zoom}
          maxZoom={5}
          aspect={1}
          cropShape="round"
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </Box>
      <Box bg="white" pt={5}>
        <Slider aria-label="Zoom Picker" defaultValue={1} min={1} max={5} step={0.1} value={zoom} onChange={setZoom}>
          <SliderTrack>
            <SliderFilledTrack/>
          </SliderTrack>
          <SliderThumb/>
        </Slider>
      </Box>
    </ModalBody>
    <ModalFooter>
      <Button variant='ghost' mr={2} onClick={() => changeStep("profile")}
              isDisabled={requestInProgress}>Cancel</Button>
      <Button colorScheme="brand" onClick={onSave} isDisabled={requestInProgress}>{requestInProgress ? 'Applying...' : 'Apply'}</Button>
    </ModalFooter>
  </>
}
